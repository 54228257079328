// @mui
import { plPL } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Polish',
    value: 'pl',
    systemValue: plPL,
    icon: '/assets/icons/flags/ic_flag_pl.svg',
  },
];

export const defaultLang = allLangs[0]; // Polish
