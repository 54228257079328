// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  estimate: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// GENERAL
// ----------------------------------------------------------------------
const generalMenu = {
  subheader: 'Dashboard',
  items: [{ title: 'Wyceny', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
};

// ESTIMATES
// ----------------------------------------------------------------------
const estimateMenu = {
  subheader: 'Wyceny',
  items: [
    // ESTIMATE
    {
      title: 'Wyceny',
      path: PATH_DASHBOARD.estimate.root,
      icon: ICONS.estimate,
      children: [
        { title: 'Lista', path: PATH_DASHBOARD.estimate.list },
        { title: 'Utwórz', path: PATH_DASHBOARD.estimate.new },
      ],
    },
  ],
};

// MANAGEMENT
// ----------------------------------------------------------------------
const adminMenu = {
  subheader: 'admin',
  items: [
    // USER
    {
      title: 'Użytkownicy',
      path: PATH_DASHBOARD.user.root,
      icon: ICONS.user,
      children: [
        { title: 'Lista', path: PATH_DASHBOARD.user.list },
        { title: 'Utwórz', path: PATH_DASHBOARD.user.new },
      ],
    },
  ],
};

export { generalMenu, estimateMenu, adminMenu };
