import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: Estimate
  EstimateAppPage,
  // User
  UserListPage,
  UserEditPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Estimate
  EstimateListPage,
  EstimateDetailsPage,
  EstimateCreatePage,
  EstimateEditPage,
  // Other
  Page500,
  Page403,
  Page404,
  MaintenancePage,
  PermissionDeniedPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        // ! TODO
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <EstimateAppPage /> },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':id/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'estimate',
          children: [
            {
              element: <Navigate to="/dashboard/estimate/list" replace />,
              index: true,
            },
            { path: 'list', element: <EstimateListPage /> },
            { path: ':id', element: <EstimateDetailsPage /> },
            { path: ':id/edit', element: <EstimateEditPage /> },
            { path: 'new', element: <EstimateCreatePage /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '/', element: <Navigate to="/dashboard" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
