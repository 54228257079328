import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const EstimateAppPage = Loadable(lazy(() => import('../pages/dashboard/EstimateAppPage')));

// DASHBOARD: INVOICE
export const EstimateListPage = Loadable(lazy(() => import('../pages/estimate/EstimateListPage')));
export const EstimateDetailsPage = Loadable(lazy(() => import('../pages/estimate/EstimateDetailsPage')));
export const EstimateCreatePage = Loadable(lazy(() => import('../pages/estimate/EstimateCreatePage')));
export const EstimateEditPage = Loadable(lazy(() => import('../pages/estimate/EstimateEditPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/user/UserProfilePage')));
export const UserListPage = Loadable(lazy(() => import('../pages/user/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/user/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/user/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/user/UserEditPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(lazy(() => import('../pages/other/PermissionDeniedPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/other/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/other/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/other/Page404')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/other/MaintenancePage')));
