export const roles = [
  { value: 'specjalista', label: 'Specjalista' },
  { value: 'lider', label: 'Lider' },
  { value: 'sprzedaż', label: 'Sprzedaż' },
  { value: 'cs', label: 'CS' },
  { value: 'tl sprzedaż', label: 'TL Sprzedaż' },
  { value: 'lider offering', label: 'Lider Offering' },
  { value: 'exec', label: 'Exec' },
  { value: 'admin', label: 'Admin' },
];

export const estimateMasterRoles = ['sprzedaż', 'cs', 'tl sprzedaż', 'lider offering', 'exec', 'admin'];
export const administrativeMasterRoles = ['exec', 'admin'];
